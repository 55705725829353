import React from "react"
import { Link } from "components/Layout/Link"
import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./SurfaceScanner.png"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Boost your quality management with the MoonVision SurfaceScanner.
        Defects on different surfaces are visually detected and differentiated
        fully automatically. The results are ideal products and significantly
        shortened quality inspections.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated quality inspection of different surfaces</li>
        <li>
          Benefit from consistent quality and reduced throughput time-detection
          and measurement by camera & software
        </li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="SurfaceScanner"
        title="Quality inspection of surfaces"
        subtitle="Inspection of surfaces with the most precise technology"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated surface inspection",
          "Consistently high production quality",
          "Reduced processing time",
        ]}
      >
        <React.Fragment>
          <p>
            MoonVision SurfaceScanner supports you in the fully automated
            inspection of surfaces. Customers benefit from the highest accuracy
            of visual inspection and manual optimizability to individual needs.
            Detected deviations from the ideal condition can also be measured
            automatically. Save time now and achieve higher quality standards
            fully automatically. The artificial intelligence used puts existing
            systems in the shade in terms of inspection accuracy - the
            evaluation process is fully automated and you save valuable time.
          </p>
          <p>
            Several cameras and exposure types and sources are used to achieve
            the highest possible accuracy. The system detects those types of
            defects that are visible to the human eye.
          </p>
          <p>
            MoonVision SurfaceScanner can inspect different types of surfaces -
            where you need it:
          </p>
          <ul>
            <li>Road quality inspection</li>
            <li>Production of statistics on greening</li>
            <li>Purity test of documents</li>
            <li>Product testing in production</li>
            <li>Effectiveness testing of cleaning equipment</li>
          </ul>
          <p>
            The MoonVision SurfaceScanner can be used at different production
            steps from incoming goods inspection to outgoing goods inspection.
            Due to its self-sufficient design, the software can be integrated
            into a running production process or existing ERP systems.
          </p>
          <h3>Available where you need it</h3>
          <p>
            MoonVision SurfaceScanner can use all existing camera systems as
            data sources - which meet the Genicam standard. We are also happy to
            advise you on the choice of the right system. The verification can
            be done in real time via our online platform. Alternatively, we can
            also install the system on site. In both cases you get access to the
            MoonVision Toolbox and can make adjustments e.g. for parameter
            adaptation and personalization at any time.
          </p>
          <h3>Simple to use</h3>
          <p>
            Due to the software design, the optimization and implementation of
            MoonVision SurfaceScanner can be realized on site with little
            effort. Already after checking a few image/video data there is
            certainty about the quality, further image data can be added at a
            later time. All data is collected and stored centrally and can be
            retrieved by you at any time.
          </p>
          <p>
            Check out our other similar products as well:
            <ul>
              <li>
                <Link to="/products/paintscanner" location={props.location}>
                  MoonVision PaintScanner
                </Link>
              </li>
              <li>
                <Link to="/products/woodscanner" location={props.location}>
                  MoonVision WoodScanner
                </Link>
              </li>
              <li>
                <Link to="/products/metalscanner" location={props.location}>
                  MoonVision MetalScanner
                </Link>
              </li>
            </ul>
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
